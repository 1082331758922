import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
  icon: {
    height: theme.SPACING.MEDIUM,
    width: theme.SPACING.MEDIUM,
    color: theme.colors['palette-grey-dark-300'],
  },
  iconContainer: {
    position: 'absolute',
    left: '10px',
    bottom: '10px',
    height: '24px',
    width: '24px',
    lineHeight: '20px',
  },
  wrapper: {
    position: 'relative',
    borderRadius: theme.SHAPE.CIRCULAR,
    height: '48px',
    width: '48px',
    padding: '8px 8px 8px 10px',
    '&:focus': {
      backgroundColor: theme.colors['grey-100'],
    },
    '&:hover': {
      backgroundColor: theme.colors['grey-200'],
      cursor: 'pointer',
    },
  },
}));
