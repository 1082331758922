import { TrendProps } from '@pushpay/charts';
import { t } from '@pushpay/i18n';

import { Direction } from '@src/types/Trend';

const getGoodDirection = (directionToCheck: Direction) =>
  directionToCheck === Direction.Flat ? undefined : directionToCheck;

export const buildTrendData = (current: number, goodDirection: Direction, previous: number): TrendProps => ({
  fromValue: previous,
  directionIsGood: getGoodDirection(goodDirection),
  labels: { increase: 'increase', decrease: 'decrease', noChange: 'no change', noComparableData: 'no comparable data' },
  tooltip: t('trendTooltip'),
  toValue: current,
});
