import * as React from 'react';

import CollapsibleBanner from '@pushpay/collapsible-banner';
import { BellIcon } from '@pushpay/iconography';
import { Column, Columns } from '@pushpay/layout';
import { Footer } from '@pushpay/shell';
import { ComponentProps } from '@pushpay/types';

import PageHeader from '@src/components/PageHeader';
import { useMyOrganization } from '@src/myContext';
import useTranslation from '@src/translations';

import { useStyles } from './pageContentStyles';
import { DataConfidenceProps } from '../DataConfidence/types';

interface PageContentProps extends React.PropsWithChildren {
  aboutContent?: () => React.ReactNode;
  lowerRightContent?: string | React.ReactNode;
  pageName?: string;
  returnTo?: string;
  title: string;
  dataConfidenceProps?: DataConfidenceProps;
}
type PageContentComponentProps = ComponentProps<PageContentProps, typeof useStyles>;

export const PageContent = ({
  aboutContent,
  children,
  classes: classesProps,
  lowerRightContent,
  pageName,
  returnTo,
  title,
  dataConfidenceProps,
}: PageContentComponentProps) => {
  const classes = useStyles(classesProps);
  const { translate } = useTranslation();
  const organization = useMyOrganization();

  const footerLogo = React.useMemo(() => {
    const { catholicSoftwareStatus, isGivingPlatformActive, productInformation } = organization?.details ?? {};
    if (isGivingPlatformActive && productInformation?.chmsInformation.isProvisioned) {
      return catholicSoftwareStatus ? 'parishStaq' : 'churchStaq';
    }
    return 'pushpay';
  }, [organization?.details]);

  const renderHeader = () => (
    <div className={classes.collapsibleBannerHeader}>
      <BellIcon className={classes.aboutIcons} />
      {translate('aboutThisPage')}
    </div>
  );

  return (
    <>
      {aboutContent && (
        <CollapsibleBanner
          classes={{ root: classes.collapsibleBannerRoot, arrow: classes.aboutIcons }}
          initialExpandStatus="collapsed"
          renderHeader={renderHeader}
          renderText={aboutContent}
          data-pp-at-target="aboutBanner"
        />
      )}
      <Columns className={classes.pageContent}>
        <Column classes={{ inner: classes.columnInner }}>
          <PageHeader
            classes={{ pageHeaderContainer: classes.pageHeaderContainer }}
            lowerRightContent={lowerRightContent}
            pageName={pageName}
            returnTo={returnTo}
            title={title}
            dataConfidenceProps={dataConfidenceProps}
          />
          {children}
        </Column>
      </Columns>
      <Footer
        classes={{ root: classes.footerRoot, centerDot: classes.centerDot, rightSide: classes.rightSide }}
        logo={footerLogo}
        data-pp-at-target="footer"
      />
    </>
  );
};
