import { Button } from '@pushpay/button';
import Expand from '@pushpay/expand';
import { Stack } from '@pushpay/layout';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { ErrorBoundary } from '@src/components/errors';
import { getAppSettings } from '@src/utils';

import { ApplicationGroup, FeatureFlagsGroup, I18nGroup, ServiceAvailabilityGroup } from './groups';
import { useTestWidgetSettings } from './useTestWidgetSettings';
import { isTestWidgetEnabled } from './utils';

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: theme.ELEVATION.POPOVER.Z_INDEX,
    borderRadius: theme.SHAPE.ROUNDED_CORNERS,
    backgroundColor: 'rgba(256, 256, 256, 0.4)',
    boxShadow: '0 0 1px 0 rgba(9,30,66,0.31), 0 0 32px -8px rgba(9,30,66,0.25)',
    backdropFilter: 'blur(2px)',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(256, 256, 256, 0.8)',
    },
  },
  build: {
    padding: '10px',
    font: theme.typography['text-5'],
    display: 'block',
    width: '100%',
    textAlign: 'left',
  },
  groups: {
    padding: '0 10px 10px',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 120px)',
    minWidth: '300px',
  },
  groupsHide: {
    display: 'none',
  },
}));

type TestWidgetCoreProps = ComponentProps<object, typeof useStyles>;

const TestWidgetCore = ({ classes: classesProps }: TestWidgetCoreProps) => {
  const { version } = getAppSettings();
  const classes = useStyles(classesProps);

  const [visible, dispatch] = useTestWidgetSettings('visible');

  return (
    <div className={classes.container}>
      <Button
        aria-label="Test Widget"
        className={classes.build}
        type="button"
        onClick={() => {
          dispatch({ type: 'showHide' });
        }}
      >
        Build: {version}
      </Button>
      <Expand.Group expandMultiple>
        <Stack className={clsx(classes.groups, !visible && classes.groupsHide)} space={10}>
          <ApplicationGroup />
          <ServiceAvailabilityGroup />
          <I18nGroup />
          <FeatureFlagsGroup />
        </Stack>
      </Expand.Group>
    </div>
  );
};

export const TestWidget = () => {
  if (!isTestWidgetEnabled()) {
    return null;
  }

  return (
    <ErrorBoundary>
      <TestWidgetCore />
    </ErrorBoundary>
  );
};
