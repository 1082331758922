import { DataDisplayType } from '@src/types/ChartAndHighlights';
import { formatCurrency, formatDisplayData, formatPercentage } from '@src/utils/formatter';

export const getPercentAsNumber = (x: number | undefined, y: number | undefined): number | undefined => {
  if (x === undefined || y === undefined) {
    return undefined;
  }

  return Math.abs(Math.round((100 * (x - y)) / y) / 100) * 100;
};

export const getPercentage = (value: number, total: number): string => {
  const percentageRatio = value / total;
  if (!Number.isFinite(percentageRatio)) {
    return '';
  }
  return formatPercentage(percentageRatio);
};

export const getAverageDonation = (givingTotal?: number, numDonations?: number): string => {
  if (!givingTotal || Number.isNaN(givingTotal) || !numDonations || Number.isNaN(numDonations)) {
    return '';
  }

  const average = givingTotal / numDonations;
  return formatCurrency(average).toString();
};

export const getObjectValuesTotal = (values: Record<any, number>): number =>
  Object.values(values).reduce((sum: number, value) => sum + value, 0);

export const simplifyComparisonValues = (
  numerator: number,
  denominator: number,
  displayData = DataDisplayType.Number,
  maxDigits = 2,
  floatCeiling = 20
): {
  numerator: string;
  denominator: string;
} => {
  if (numerator === 0 || denominator === 0) {
    return {
      numerator: formatDisplayData(numerator, displayData),
      denominator: formatDisplayData(denominator, displayData),
    };
  }

  if (displayData === DataDisplayType.Currency) {
    const value = numerator / denominator;
    return {
      numerator: formatDisplayData(value, displayData),
      denominator: formatDisplayData(1, displayData),
    };
  }

  const useNumerator = Math.abs(numerator) > Math.abs(denominator);
  const value = useNumerator ? numerator / denominator : denominator / numerator;
  const limitDigits = Math.abs(value) < floatCeiling ? maxDigits : 0;

  return {
    numerator: useNumerator ? formatDisplayData(value, displayData, limitDigits) : '1',
    denominator: useNumerator ? '1' : formatDisplayData(value, displayData, limitDigits),
  };
};
