import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

import { getBasePageStyles } from '@src/utils';

export const useStyles = createUseStyles((theme: Theme) => ({
  ...getBasePageStyles(theme),
  illustration: {
    height: '158px',
    width: '158px',
  },
  pageContent: {
    alignItems: 'center',
    backgroundColor: theme.colors['color-background-surface'],
    borderRadius: theme.SHAPE.ROUNDED_CORNERS,
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    height: '100%',
    justifyContent: 'center',
    padding: theme.SPACING.SMALL,
    textAlign: 'center',
  },
  pageContainer: {
    width: '100%',
    height: '100%',
  },
  errorText: {
    color: theme.colors['color-text-hint'],
    fontSize: '18px',
  },
}));
