import * as ReactRouterDOM from 'react-router-dom';

import { Permission, Permissions } from '@pushpay/identity.permissions';

import { EntitlementKey } from '@src/graphql/generated';
import { useMyEntitlements, useMyPermissions } from '@src/myContext';

type AuthorizedRouteObject = Omit<ReactRouterDOM.RouteObject, 'index' | 'children'> & {
  permission?: Permission;
  entitlement?: EntitlementKey;
  children?: AuthorizedRouteObject[];
};

export function useAuthorizedRoutes(routes: AuthorizedRouteObject[]) {
  const { hasOrganizationPermission } = useMyPermissions();
  const { hasOrganizationEntitlement } = useMyEntitlements();

  function byPermission({ permission }: AuthorizedRouteObject) {
    return !permission || permission === Permissions.basic || hasOrganizationPermission(permission);
  }

  function byEntitlement({ entitlement }: AuthorizedRouteObject) {
    return !entitlement || hasOrganizationEntitlement(entitlement);
  }

  function mapRoutes(authorizedRoutes: AuthorizedRouteObject[]): AuthorizedRouteObject[] {
    return authorizedRoutes
      .filter(byPermission)
      .filter(byEntitlement)
      .map(route => ({ ...route, children: route.children && mapRoutes(route.children) }));
  }

  return ReactRouterDOM.useRoutes(mapRoutes(routes));
}
