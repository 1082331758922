import * as React from 'react';

import moment from 'moment'; // eslint-disable-line no-restricted-imports

import { UseTranslationOptions } from '@pushpay/i18n';

import { isTestWidgetEnabled, useI18nTransforms } from '@src/components/testWidget';
import { insertIf } from '@src/utils';

import {
  useTranslation as useTranslationBase,
  Translate as TranslateBase,
  NamespaceKey,
  getTransWithDefaultNamespace,
  TranslationLanguage,
} from './translation';

function usePostProcessors() {
  const testWidgetTransforms = useI18nTransforms();
  return React.useMemo(() => [...insertIf(isTestWidgetEnabled(), testWidgetTransforms)], [testWidgetTransforms]);
}

function useTranslation<TNamespaceKey extends NamespaceKey = 'common'>(
  namespace: TNamespaceKey = 'common' as TNamespaceKey,
  options?: UseTranslationOptions
) {
  const { translate, keyExists, language, changeLanguage, onLanguageChange, translateMoment } = useTranslationBase(
    namespace,
    options,
    usePostProcessors()
  );

  const translateDate = (date: Date) => {
    translateMoment(moment(date));
  };

  return {
    translate,
    translateDate,
    keyExists,
    language,
    changeLanguage,
    onLanguageChange,
  };
}

export default useTranslation;
export type Translate = TranslateBase<'common'>;
export type TranslateDate = ReturnType<typeof useTranslation>['translateDate'];
export type TranslateKey<Namespace extends NamespaceKey = 'common'> = keyof TranslationLanguage[Namespace];

export const Trans = getTransWithDefaultNamespace('common');
