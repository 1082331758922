import { createUseStyles } from '@pushpay/styles';
import { ChMSTheme, Theme } from '@pushpay/theming';

import { getBaseButtonStyles, getBaseHeaderStyles } from '@src/utils';

export const useStyles = createUseStyles((theme: Theme) => ({
  breadcrumb: {
    paddingLeft: 0,
    margin: '24px 0',
  },
  breadcrumbRoot: {
    height: '20px',
    alignItems: 'center',
  },
  itemLink: {
    color: theme.colors['blue-600'],
    fontWeight: '600',
  },
  columns: {
    marginBottom: '24px',
  },
  dataConfidenceWrapper: {
    marginTop: '2px',
  },
  link: {
    color: theme.colors['teal-500'],
    marginRight: '1em',
  },
  pageHeaderContainer: {
    flexDirection: 'column',
  },
  rightContent: {
    color: theme.colors['grey-800'],
  },
  titleRoot: {
    ...getBaseHeaderStyles(theme).h1,
    display: 'flex',
    alignItems: 'center',
    gap: theme.SPACING.XXSMALL,
  },
  title: {
    alignItems: 'center',
  },
  ...getBaseButtonStyles(theme),
  ...getBaseHeaderStyles(theme),
}));
