import { useApolloClient } from '@apollo/client';
import * as ReactRouterDOM from 'react-router-dom';

import { MyOrganizationDocument, MyOrganizationQuery } from '@src/graphql/generated';

export function useMyOrganization() {
  const client = useApolloClient();
  const { organizationKey } = ReactRouterDOM.useParams<'organizationKey'>();

  if (!organizationKey) {
    throw new Error('useMyOrganization must be used within an Organization route context');
  }

  const data = client.readQuery<MyOrganizationQuery>({
    query: MyOrganizationDocument,
    variables: { organizationKey },
  });

  if (data === null) {
    throw new Error('useMyOrganization must be used within child components of MyOrganization');
  }

  return data.organization as NonNullable<MyOrganizationQuery['organization']>;
}
