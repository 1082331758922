import * as React from 'react';

import * as ReactDOM from 'react-dom/client';
import * as ReactRouterDOM from 'react-router-dom';

import { AuthProvider } from '@pushpay/auth';
import Reset from '@pushpay/reset';
import { EngagementAnalyticsTheme, ThemeProvider } from '@pushpay/theming';

import { AccessDenied, ErrorBoundary } from '@src/components/errors';
import { getAppSettings } from '@src/utils';

import loadI18n from './i18n';

const Shell = React.lazy(() => import(/* webpackPreload: true, webpackChunkName: 'shell' */ '@src/pages/shell'));

export const Root = () => {
  const { authConfiguration } = getAppSettings();
  const navigate = ReactRouterDOM.useNavigate();

  return (
    // the authentication flow effect should not run twice so sits outside of StrictMode
    <ThemeProvider theme={EngagementAnalyticsTheme}>
      <Reset />
      <AuthProvider
        config={authConfiguration}
        redirect={(url: string) => navigate(url, { replace: true })}
        renderError={() => <AccessDenied />}
      >
        {/* StrictMode is messing up animations (in react-spring), so is being temporarily commented out */}
        {/* <React.StrictMode> */}
        <React.Suspense>
          <Shell />
        </React.Suspense>
        {/* </React.StrictMode> */}
      </AuthProvider>
    </ThemeProvider>
  );
};

loadI18n();

const rootElement = document.getElementById('root');
if (rootElement) {
  const router = ReactRouterDOM.createBrowserRouter([
    {
      path: '*',
      element: <Root />,
      errorElement: <ErrorBoundary />,
    },
  ]);

  ReactDOM.createRoot(rootElement).render(<ReactRouterDOM.RouterProvider router={router} />);
}
