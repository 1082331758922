import * as React from 'react';

import { EntitlementKey } from '@src/graphql/generated';

import { useMyOrganization } from './myOrganization';

export function useMyEntitlements() {
  const organization = useMyOrganization();

  const hasOrganizationEntitlement = React.useCallback(
    (entitlement: EntitlementKey) => {
      const entitlements = organization?.details?.entitlements ?? [];
      entitlements?.some(e => e.key === entitlement);
    },
    [organization?.details?.entitlements]
  );

  return {
    hasOrganizationEntitlement,
  };
}
