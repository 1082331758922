import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
  column: { margin: theme.SPACING.NONE },
  columns: {
    margin: theme.SPACING.NONE,

    [theme.mediaBreakpoint('TABLET_AND_BELOW')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  header: {
    alignSelf: 'center',
    backgroundColor: theme.colors['common-transparent'],
    border: theme.SPACING.NONE,
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  inner: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.SPACING.NONE,
    paddingLeft: theme.SPACING.NONE,
  },
  innerRight: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    margin: theme.SPACING.NONE,
    paddingLeft: theme.SPACING.NONE,

    [theme.mediaBreakpoint('TABLET_AND_BELOW')]: {
      justifyContent: 'flex-start',
      marginTop: theme.SPACING.XSMALL,
    },
  },
}));
