import { useCallback } from 'react';

import { useTestWidgetSettings } from '../useTestWidgetSettings';
import {
  transformAccents,
  transformBrackets,
  transformExtraLength,
  transformMirror,
  transformUnderscores,
} from './transforms';

export function useI18nTransforms() {
  const [i18n] = useTestWidgetSettings('i18n');

  return useCallback(
    (text: string) => {
      const { accents, brackets, extraLength, mirror, underscores } = i18n;
      let transformedText = text.slice();

      if (accents) {
        transformedText = transformAccents(transformedText);
      }
      if (brackets) {
        transformedText = transformBrackets(transformedText);
      }
      if (extraLength) {
        transformedText = transformExtraLength(transformedText, extraLength);
      }
      if (mirror) {
        transformedText = transformMirror(transformedText);
      }
      if (underscores) {
        transformedText = transformUnderscores(transformedText);
      }
      return transformedText;
    },
    [i18n]
  );
}
