import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';

export const useStyles = createUseStyles((theme: Theme) => ({
  pageContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
  },
  pageContent: {
    alignItems: 'center',
    backgroundColor: theme.colors['color-background-surface'],
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    height: '100%',
    justifyContent: 'center',
    padding: theme.SPACING.SMALL,
    textAlign: 'center',
  },
  errorText: {
    fontSize: '18px',
  },
  illustration: {
    height: '158px',
    width: '158px',
  },
  link: {
    color: theme.colors['productBrand-600'],
    marginInline: '0.35em',
    textDecoration: 'underline',
  },
  title: {
    fontSize: '32px',
    fontWeight: '400',
    marginBottom: '0.5em',
  },
}));
