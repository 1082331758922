import * as ReactRouterDOM from 'react-router-dom';

import { Destination, RoutePath } from '@src/types/Routing';

const DASHBOARD_LINK_PATH = `${RoutePath.OrganizationPath}${RoutePath.DashboardPath}`;
const HOME_LINK_PATH = `${RoutePath.OrganizationPath}${RoutePath.HomePath}`;
const GIVING_LINK_PATH = `${RoutePath.OrganizationPath}${RoutePath.GivingPath}`;
const SERVING_LINK_PATH = `${RoutePath.OrganizationPath}${RoutePath.ServingPath}`;
const ATTENDANCE_LINK_PATH = `${RoutePath.OrganizationPath}${RoutePath.AttendancePath}`;
const PEOPLE_LIST_LINK_PATH = `${RoutePath.OrganizationPath}${RoutePath.PeopleListPath}`;
const DATA_CUSTOMIZATION_LINK_PATH = `${RoutePath.OrganizationPath}${RoutePath.DataCustomizationPath}`;
const GROUP_PARTICIPATION_LINK_PATH = `${RoutePath.OrganizationPath}${RoutePath.GroupParticipationPath}`;

export const getPath = (destination: Destination, organizationKey = '', urlVariables?: Record<string, string>) => {
  let basePath = '';

  if (destination === Destination.Organization) {
    basePath = ReactRouterDOM.generatePath(RoutePath.OrganizationPath, { organizationKey });
  }
  if (destination === Destination.Dashboard) {
    basePath = ReactRouterDOM.generatePath(DASHBOARD_LINK_PATH, { organizationKey });
  }
  if (destination === Destination.Home) {
    basePath = ReactRouterDOM.generatePath(HOME_LINK_PATH, { organizationKey });
  }
  if (destination === Destination.GivingOverview) {
    basePath = ReactRouterDOM.generatePath(GIVING_LINK_PATH, { organizationKey });
  }
  if (destination === Destination.ServingOverview) {
    basePath = ReactRouterDOM.generatePath(SERVING_LINK_PATH, { organizationKey });
  }
  if (destination === Destination.AttendanceOverview) {
    basePath = ReactRouterDOM.generatePath(ATTENDANCE_LINK_PATH, { organizationKey });
  }
  if (destination === Destination.DataCustomization) {
    basePath = ReactRouterDOM.generatePath(DATA_CUSTOMIZATION_LINK_PATH, { organizationKey });
  }
  if (destination === Destination.PeopleList) {
    basePath = ReactRouterDOM.generatePath(PEOPLE_LIST_LINK_PATH, { organizationKey });
  }
  if (destination === Destination.GroupParticipationOverview) {
    basePath = ReactRouterDOM.generatePath(GROUP_PARTICIPATION_LINK_PATH, { organizationKey });
  }

  if (urlVariables) {
    return `${basePath}?${Object.entries(urlVariables)
      .filter(([_, value]) => value !== '')
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`;
  }

  return basePath;
};
