import { Button } from '@pushpay/button';
import { Select } from '@pushpay/inputs';
import { StackItem } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import useTranslation from '@src/translations';

import { Group, SettingNumberInput, SettingSwitch } from '../components';
import { extraLengthMax } from '../testWidgetSettings';
import { useTestWidgetSettings } from '../useTestWidgetSettings';

const useStyles = createUseStyles((theme: Theme) => ({
  selectLanguage: {
    width: '100%',
  },
  selectLanguageSummary: {
    padding: '5px',
    width: '100%',
  },
  selectLanguageOverlay: {
    position: 'fixed',
    background: theme.colors['common-white'],
    boxShadow: theme.ELEVATION.EIGHT,
    width: '244px',
  },
}));

const groupId = 'i18n';

export const I18nGroup = ({ classes: classesProp, ...rest }: ComponentProps<unknown, typeof useStyles>) => {
  const classes = useStyles(classesProp);
  const { language, changeLanguage } = useTranslation();
  const [{ accents, brackets, extraLength, mirror, underscores }, dispatch] = useTestWidgetSettings('i18n');

  return (
    <Group groupName="i18n" {...rest}>
      <StackItem alignH="right">
        <Button
          displaySize="small"
          displayStyle="text"
          type="button"
          onClick={() => {
            changeLanguage('en');
            dispatch({ type: 'i18nReset' });
          }}
        >
          Reset
        </Button>
      </StackItem>
      <Select
        classes={{
          root: classes.selectLanguage,
          summary: classes.selectLanguageSummary,
          overlay: classes.selectLanguageOverlay,
        }}
        id={`${groupId}-select-language`}
        options={[
          { display: 'English', value: 'en' },
          { display: 'Española', value: 'es' },
        ]}
        value={language}
        onChange={selectedLanguage => {
          changeLanguage(selectedLanguage);
        }}
      />
      <SettingSwitch
        checked={accents}
        id={`${groupId}-accents`}
        onChange={checked => dispatch({ type: 'i18n', accents: checked })}
      >
        Mark with accents
      </SettingSwitch>
      <SettingSwitch
        checked={brackets}
        id={`${groupId}-brackets`}
        onChange={checked => dispatch({ type: 'i18n', brackets: checked })}
      >
        Brackets start and end
      </SettingSwitch>
      <SettingSwitch
        checked={mirror}
        id={`${groupId}-mirror`}
        onChange={checked => dispatch({ type: 'i18n', mirror: checked })}
      >
        Mirror text
      </SettingSwitch>
      <SettingSwitch
        checked={underscores}
        id={`${groupId}-underscores`}
        onChange={checked => dispatch({ type: 'i18n', underscores: checked })}
      >
        Replace with underscores
      </SettingSwitch>
      <SettingNumberInput
        max={extraLengthMax}
        value={extraLength}
        onChange={(extraLengthPercentage: number | null) =>
          dispatch({ type: 'i18n', extraLength: extraLengthPercentage })
        }
      >
        Extra length (%)
      </SettingNumberInput>
    </Group>
  );
};
