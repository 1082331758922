import { featureFlagManager } from '@src/featureFlags';

import {
  initialSettings,
  loadTestWidgetSettings,
  saveTestWidgetSettings,
  TestWidgetSettings,
  TestWidgetSettingsAction,
} from './testWidgetSettings';

class TestWidgetManager {
  private _listeners = new Set<() => void>();

  private _settings: TestWidgetSettings;

  constructor() {
    this._settings = loadTestWidgetSettings();
  }

  private broadcastSettingsUpdated = () => {
    this._listeners.forEach(onSettingsChanged => onSettingsChanged());
  };

  listen = (onSettingsChanged: () => void) => {
    this._listeners.add(onSettingsChanged);
    return () => {
      this._listeners.delete(onSettingsChanged);
    };
  };

  private getNextSettings(action: TestWidgetSettingsAction): TestWidgetSettings {
    if (action.type === 'showHide') {
      return { ...this._settings, visible: !this._settings.visible };
    }

    if (action.type === 'applicationReset') {
      return { ...this._settings, application: initialSettings.application };
    }

    if (action.type === 'serviceAvailabilityReset') {
      return { ...this._settings, serviceAvailability: initialSettings.serviceAvailability };
    }

    if (action.type === 'i18nReset') {
      return { ...this._settings, i18n: initialSettings.i18n };
    }

    if (action.type === 'featureFlagOverridesReset') {
      return { ...this._settings, featureFlagOverrides: initialSettings.featureFlagOverrides };
    }

    const { type, ...settings } = action;

    return {
      ...this._settings,
      [type]: {
        ...this._settings[type],
        ...settings,
      },
    };
  }

  updateSettings = (action: TestWidgetSettingsAction) => {
    this._settings = this.getNextSettings(action);
    saveTestWidgetSettings(this._settings);
    this.broadcastSettingsUpdated();

    if (action.type === 'featureFlagOverrides' || action.type === 'featureFlagOverridesReset') {
      featureFlagManager.broadcastFlagsUpdated();
    }
  };

  get settings() {
    return Object.freeze(this._settings);
  }
}

export const testWidgetManager = new TestWidgetManager();
