import * as React from 'react';

import { useApolloClient } from '@apollo/client';

import { Button } from '@pushpay/button';
import { StackItem } from '@pushpay/layout';
import { clsx, createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { FeatureFlag, featureFlagManager } from '@src/featureFlags';
import { FeatureFlagsFragment, FeatureFlagsFragmentDoc } from '@src/graphql/generated';

import { Group, SettingSwitch } from '../../components';
import { useTestWidgetFeatureFlags } from './useTestWidgetFeatureFlags';

const useStyles = createUseStyles((theme: Theme) => ({
  switchFlagOverriden: {
    color: theme.colors['green-400'],
    backgroundColor: 'currentColor',
    borderColor: 'currentColor',
  },
}));

const groupId = 'feature-flags';

type FeatureFlagsGroupComponentProps = ComponentProps<{ refetchOrganizationFeatureFlags?: boolean }, typeof useStyles>;

export const FeatureFlagsGroup = ({
  classes: classesProp,
  'data-pp-at-target': targetId,
}: FeatureFlagsGroupComponentProps) => {
  const classes = useStyles(classesProp);
  const { allFeatureFlags, featureFlagOverrides, dispatch } = useTestWidgetFeatureFlags();
  const { cache } = useApolloClient();

  const refetchOrgFlags = React.useCallback(() => {
    const featureFlagsFragment = cache.readFragment<FeatureFlagsFragment>({
      id: cache.identify({ __typename: 'Organization' }),
      fragment: FeatureFlagsFragmentDoc,
    });
    if (featureFlagsFragment) {
      featureFlagManager.setOrganizationOverrides(featureFlagsFragment.featureFlags);
    }
  }, [cache]);

  return (
    <Group data-pp-at-target={targetId} groupName="Feature Flags">
      <StackItem alignH="right">
        <Button
          displaySize="small"
          displayStyle="text"
          type="button"
          onClick={() => {
            dispatch({ type: 'featureFlagOverridesReset' });
            refetchOrgFlags();
          }}
        >
          Reset
        </Button>
      </StackItem>
      {allFeatureFlags.map(flag => (
        <SettingSwitch
          key={flag.name}
          checked={flag.value}
          classes={{
            switch: clsx(flag.name in featureFlagOverrides && classes.switchFlagOverriden),
          }}
          id={`${groupId}-${flag.name}`}
          onChange={flagValue =>
            dispatch({
              type: 'featureFlagOverrides',
              ...({ [flag.name]: flagValue } as Record<FeatureFlag, boolean>),
            })
          }
        >
          {flag.name}
        </SettingSwitch>
      ))}
    </Group>
  );
};
