import * as React from 'react';

import { featureFlagManager } from '@src/featureFlags';

import { useTestWidgetSettings } from '../../useTestWidgetSettings';

export function useTestWidgetFeatureFlags() {
  const [featureFlagOverrides, dispatch] = useTestWidgetSettings('featureFlagOverrides');
  const [allFeatureFlags, setAllFeatureFlags] = React.useState(featureFlagManager.allFlags);

  React.useEffect(function listToFeatureFlagValueChanges() {
    return featureFlagManager.listen(() => setAllFeatureFlags(featureFlagManager.allFlags));
  }, []);

  return {
    allFeatureFlags,
    featureFlagOverrides,
    setOrganizationOverrides: featureFlagManager.setOrganizationOverrides,
    dispatch,
  };
}
