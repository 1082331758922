import { Theme } from '@pushpay/theming';

export const getBasePageStyles = (theme: Theme) => ({
  h1: {
    padding: '5px 6px',
    '&:hover': {
      outline: 'none',
      backgroundColor: theme.colors['common-white'],
      border: 'solid',
      borderColor: theme.colors['grey-500'],
      borderRadius: theme.SHAPE.ROUNDED_CORNERS,
      borderWidth: '1px',
      marginTop: '-0.35em',
      paddingTop: '0.5em',
    },
  },
  flexContainer: {
    display: 'flex',
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  pageContent: {
    padding: '0 16px',
  },
});
