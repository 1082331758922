import { Button } from '@pushpay/button';
import { StackItem } from '@pushpay/layout';

import { Group, SettingNumberInput, SettingSwitch } from '../components';
import { useTestWidgetSettings } from '../useTestWidgetSettings';

const groupId = 'service-availability';

export const ServiceAvailabilityGroup = () => {
  const [
    {
      delay,
      attendance_query: attendanceQuery,
      'engagement-analytics-app': engagementAnalyticsApi,
      identity,
      organization,
      community,
      payment,
      serving_query: servingQuery,
      insights_export: insightsExport,
      chms,
    },
    dispatch,
  ] = useTestWidgetSettings('serviceAvailability');

  return (
    <Group groupName="Service Availability">
      <StackItem alignH="right">
        <Button
          displaySize="small"
          displayStyle="text"
          type="button"
          onClick={() => dispatch({ type: 'serviceAvailabilityReset' })}
        >
          Reset
        </Button>
      </StackItem>
      <SettingNumberInput
        max={10000}
        value={delay}
        onChange={(newDelay: number | null) => dispatch({ type: 'serviceAvailability', delay: newDelay })}
      >
        Delay (ms)
      </SettingNumberInput>
      <SettingSwitch
        checked={attendanceQuery}
        id={`${groupId}-attendance-query`}
        onChange={available => dispatch({ type: 'serviceAvailability', attendance_query: available })}
      >
        Attendance Query
      </SettingSwitch>
      <SettingSwitch
        checked={engagementAnalyticsApi}
        id={`${groupId}-engagement-analytics-api`}
        onChange={available => dispatch({ type: 'serviceAvailability', 'engagement-analytics-app': available })}
      >
        Engagement Analytics API
      </SettingSwitch>
      <SettingSwitch
        checked={identity}
        id={`${groupId}-identity`}
        onChange={available => dispatch({ type: 'serviceAvailability', identity: available })}
      >
        Identity service
      </SettingSwitch>
      <SettingSwitch
        checked={payment}
        id={`${groupId}-payment`}
        onChange={available => dispatch({ type: 'serviceAvailability', payment: available })}
      >
        Payment Query
      </SettingSwitch>
      <SettingSwitch
        checked={servingQuery}
        id={`${groupId}-serving-query`}
        onChange={available => dispatch({ type: 'serviceAvailability', serving_query: available })}
      >
        Serving Query
      </SettingSwitch>
      <SettingSwitch
        checked={organization}
        id={`${groupId}-organization`}
        onChange={available => dispatch({ type: 'serviceAvailability', organization: available })}
      >
        Organization service
      </SettingSwitch>
      <SettingSwitch
        checked={community}
        id={`${groupId}-community`}
        onChange={available => dispatch({ type: 'serviceAvailability', community: available })}
      >
        Community service
      </SettingSwitch>
      <SettingSwitch
        checked={insightsExport}
        id={`${groupId}-insights-export`}
        onChange={available => dispatch({ type: 'serviceAvailability', insights_export: available })}
      >
        Insights Export
      </SettingSwitch>
      <SettingSwitch
        checked={chms}
        id={`${groupId}-chms`}
        onChange={available => dispatch({ type: 'serviceAvailability', chms: available })}
      >
        ChMS
      </SettingSwitch>
    </Group>
  );
};
