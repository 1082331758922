import * as React from 'react';

import { DataChartStatusIcon } from '@pushpay/iconography';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { useStyles } from './dataConfidenceStyles';
import { DataConfidenceProps } from './types';

type DataConfidenceComponentProps = ComponentProps<DataConfidenceProps, typeof useStyles>;

export const DataConfidence = ({ classes: classesProps, id, status, tooltip }: DataConfidenceComponentProps) => {
  const classes = useStyles(classesProps);

  return (
    <div id={id} className={classes.wrapper} data-pp-at-target="dataConfidenceWrapper">
      <div className={classes.iconContainer}>
        <Tooltip content={tooltip} data-pp-at-target="dataConfidence-tooltip" placement="top">
          <DataChartStatusIcon classes={{ root: classes.icon }} status={status} />
        </Tooltip>
      </div>
    </div>
  );
};
