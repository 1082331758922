import { useEffect, useState } from 'react';

import { testWidgetManager } from './testWidgetManager';
import { TestWidgetSettings, TestWidgetSettingsGroup } from './testWidgetSettings';

export function useTestWidgetSettings<TGroup extends TestWidgetSettingsGroup>(group: TGroup) {
  const [settings, setSettings] = useState(testWidgetManager.settings[group]);

  useEffect(
    function listenToFeatureFlagValueChanges() {
      return testWidgetManager.listen(() => setSettings(testWidgetManager.settings[group]));
    },
    [group]
  );

  return [settings, testWidgetManager.updateSettings] as [
    TestWidgetSettings[TGroup],
    typeof testWidgetManager.updateSettings
  ];
}
