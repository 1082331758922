export enum Destination {
  Organization,
  Dashboard,
  DataCustomization,
  Home,
  GivingOverview,
  ServingOverview,
  AttendanceOverview,
  PeopleList,
  GroupParticipationOverview,
}

export enum RoutePath {
  OrganizationPath = '/o/:organizationKey',
  DashboardPath = '/dashboard',
  DataCustomizationPath = '/settings/data_customization',
  HomePath = '/home',
  GivingPath = '/giving',
  ServingPath = '/serving',
  AttendancePath = '/attendance',
  PeopleListPath = '/people_list',
  GroupParticipationPath = '/group_participation',
}
