import { NumberInput, NumberInputProps } from '@pushpay/inputs';
import { createUseStyles, ClassesProp } from '@pushpay/styles';

const useStyles = createUseStyles({
  wrapper: {
    width: '100px',
    display: 'inline-block',
  },
  field: {
    padding: '5px',
  },
  label: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '5px',
  },
});

type SettingNumberInputComponentProps = Omit<NumberInputProps, 'id' | 'classes'> & ClassesProp<typeof useStyles>;

export const SettingNumberInput = (props: SettingNumberInputComponentProps) => {
  const { classes: classesProp, children, 'data-pp-at-target': targetId, ...rest } = props;
  const classes = useStyles(classesProp);

  return (
    <>
      <NumberInput
        classes={{
          field: classes.field,
        }}
        className={classes.wrapper}
        data-pp-at-target={`${targetId}-input`}
        id={`${targetId}-input`}
        min={0}
        {...rest}
      />
      <label className={classes.label} htmlFor={`${targetId}-label`}>
        {children}
      </label>
    </>
  );
};
