import { DataDisplayType } from '@src/types/ChartAndHighlights';

import { simplifyComparisonValues } from './math';

export const formatCount = (number: number, maximumFractionDigits = 0, minimumFractionDigits = 0): string => {
  const preventRangeError =
    minimumFractionDigits > maximumFractionDigits ? minimumFractionDigits : maximumFractionDigits;
  return number.toLocaleString(undefined, { maximumFractionDigits: preventRangeError, minimumFractionDigits });
};

export const formatCurrency = (value: number | null): string => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
  if (value === null || Number.isNaN(value)) {
    return '$0.00';
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return formatter.format(value!);
};

export const formatPercentage = (number: number): string =>
  new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 1 }).format(number);

export const formatRatio = (data: number[], displayData?: DataDisplayType, maxDigits?: number): string => {
  const { numerator, denominator } = simplifyComparisonValues(data[0], data[1], displayData, maxDigits);
  return `${numerator} : ${denominator}`;
};

export const formatDisplayData = (data: number, dataDisplayType?: DataDisplayType, maxDigits?: number): string => {
  switch (dataDisplayType) {
    case DataDisplayType.Currency:
      return formatCurrency(data);
    case DataDisplayType.Percentage:
      return formatPercentage(data);
    default:
      return formatCount(data, maxDigits);
  }
};
