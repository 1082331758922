export enum Direction {
  Down = 'down',
  Flat = 'flat',
  Up = 'up',
}

export enum ComparisonType {
  Down = 'down',
  NoChange = 'noChange',
  NoComparableData = 'noComparableData',
  Up = 'up',
}
