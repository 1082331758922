import {
  DatePeriod,
  getDateRangeFromDurationAndPeriod,
  getPreviousDateRange as getPreviousDateRangeUtils,
  Frequency,
  DateFormats,
  DateComparisonType,
  RangeType,
} from '@churchcommunitybuilder/js-utils';

import { DateConfig } from '@src/types/DateConfig';
import { DateRangeOptionType } from '@src/types/DateRangeSelector';

import { formatDate } from './date';

export type Instant = moment.Moment;

export const getDateRangeOptionFromSimpleDateConfig = (
  duration: number,
  period: DatePeriod,
  includeCurrent: boolean
): DateRangeOptionType => {
  if (duration === 1 && period === DatePeriod.Week && includeCurrent === true) {
    return DateRangeOptionType.ThisWeek;
  }

  if (duration === 1 && period === DatePeriod.Week && includeCurrent === false) {
    return DateRangeOptionType.LastWeek;
  }

  if (duration === 12 && period === DatePeriod.Week && includeCurrent === false) {
    return DateRangeOptionType.Last12Weeks;
  }

  if (duration === 1 && period === DatePeriod.Month && includeCurrent === true) {
    return DateRangeOptionType.ThisMonth;
  }

  if (duration === 1 && period === DatePeriod.Month && includeCurrent === false) {
    return DateRangeOptionType.LastMonth;
  }

  return DateRangeOptionType.Last12Months;
};

export const getDurationPeriodAndCurrentFromDateRangeOption = (
  dateRangeOption: DateRangeOptionType
): {
  duration: number;
  includeCurrent: boolean;
  period: DatePeriod;
} => {
  let duration = 1;
  let period = DatePeriod.Week;
  let includeCurrent = false;

  switch (dateRangeOption) {
    case DateRangeOptionType.ThisWeek:
      includeCurrent = true;
      break;
    case DateRangeOptionType.LastWeek:
      break;
    case DateRangeOptionType.Last12Weeks:
      duration = 12;
      break;
    case DateRangeOptionType.ThisMonth:
      period = DatePeriod.Month;
      includeCurrent = true;
      break;
    case DateRangeOptionType.LastMonth:
      period = DatePeriod.Month;
      break;
    case DateRangeOptionType.Last12Months:
    default:
      duration = 12;
      period = DatePeriod.Month;
      break;
  }

  return { duration, period, includeCurrent };
};

type ReturnStartAndEndDates = { startDate: Date; endDate: Date };

export const getDateRange = (dateRangeOption: DateRangeOptionType): ReturnStartAndEndDates => {
  const { duration, period, includeCurrent } = getDurationPeriodAndCurrentFromDateRangeOption(dateRangeOption);

  return getDateRangeFromDurationAndPeriod(duration, period, includeCurrent);
};

export const getDateRangeFromRangeType = (dateConfig: DateConfig): ReturnStartAndEndDates => {
  if (dateConfig.rangeType === RangeType.Simple) {
    return getDateRangeFromDurationAndPeriod(dateConfig.duration, dateConfig.period, dateConfig.includeCurrent);
  }

  return { startDate: dateConfig.startDate, endDate: dateConfig.endDate };
};

export const getPreviousDateRange = (
  dateRangeOption: DateRangeOptionType,
  dateComparisonType: DateComparisonType
): ReturnStartAndEndDates => {
  const { duration, period, includeCurrent } = getDurationPeriodAndCurrentFromDateRangeOption(dateRangeOption);

  return getPreviousDateRangeUtils(dateComparisonType, duration, includeCurrent, period);
};

export const getDateRangeDisplay = (startDate: Date, endDate: Date, unit?: Frequency | DatePeriod): string => {
  // A bunch of the cases below do not localize the format, month before day vs day before month
  // MMM d, yyyy will always render month before day which is consistent
  const formattedStartDate = formatDate(startDate, 'MMM d, yyyy' as DateFormats);
  const formattedEndDate = formatDate(endDate, 'MMM d, yyyy' as DateFormats);
  const startDay = formatDate(startDate, DateFormats.D);
  const endDay = formatDate(endDate, DateFormats.D);
  const startMonth = formatDate(startDate, DateFormats.MMM);
  const endMonth = formatDate(endDate, DateFormats.MMM);
  const startYear = formatDate(startDate, DateFormats.YYYY);
  const endYear = formatDate(endDate, DateFormats.YYYY);

  // Day Formats
  if (unit === Frequency.Daily || unit === DatePeriod.Day) {
    // Day Format (same day): Jan 1, 2023
    if (formattedStartDate === formattedEndDate) {
      return formattedStartDate;
    }
  }

  // Month Formats
  if (unit === Frequency.Monthly || unit === DatePeriod.Month) {
    if (startMonth === endMonth) {
      if (startYear === endYear) {
        // Month Format (same month/same year): Jan 2023
        return `${endMonth} ${endYear}`;
      }
    }

    // Month Format (different month/same year): Jan - Feb 2023
    if (startYear === endYear) {
      return `${startMonth} - ${endMonth} ${endYear}`;
    }

    // Month Format (different month/different year): Jan 2023 - Feb 2024
    return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
  }

  // Year Formats
  if (unit === Frequency.Yearly || unit === DatePeriod.Year) {
    // Year Format (different year)
    if (startYear !== endYear) {
      return `${startYear} - ${endYear}`;
    }
    return endYear;
  }

  // Day/Week Formats
  // Day/Week Format (same month/same year): Jan 2-8, 2023
  if (startMonth === endMonth) {
    if (startYear === endYear) {
      return `${startMonth} ${startDay}-${endDay}, ${endYear}`;
    }
  }

  // Day/Week Format (different month/same year): Feb 27 - Mar 5, 2023
  if (startYear === endYear) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
  }

  // Default - fully formatted start and end date: Dec 27, 2022 - Jan 1, 2023
  return `${formattedStartDate} - ${formattedEndDate}`;
};
