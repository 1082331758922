import { isTestWidgetEnabled, testWidgetManager } from '@src/components/testWidget';
import { MyOrganizationQuery } from '@src/graphql/generated';
import { getAppSettings, notEmpty } from '@src/utils';

import { FeatureFlag, FeatureFlags } from './types';

class FeatureFlagManager {
  private _flagListeners = new Set<() => void>();

  private _organizationOverrides: Partial<FeatureFlags> = {};

  private readonly _staticFlags: Partial<FeatureFlags>;

  constructor() {
    const { featureFlagsDoNotUseUnlessYouKnowWhatYouAreDoing: featureFlagsDoNotUse } = getAppSettings();
    this._staticFlags = featureFlagsDoNotUse;
  }

  broadcastFlagsUpdated = () => {
    this._flagListeners.forEach(onFeatureFlagsChanged => onFeatureFlagsChanged());
  };

  listen = (onFeatureFlagsChanged: () => void) => {
    this._flagListeners.add(onFeatureFlagsChanged);
    return () => {
      this._flagListeners.delete(onFeatureFlagsChanged);
    };
  };

  setOrganizationOverrides = (
    organizationOverrides: NonNullable<NonNullable<MyOrganizationQuery['organization']>['featureFlags']>
  ) => {
    this._organizationOverrides = organizationOverrides.filter(notEmpty).reduce((acc, { name, enabled }) => {
      acc[name as FeatureFlag] = enabled;
      return acc;
    }, {} as Partial<FeatureFlags>);
    this.broadcastFlagsUpdated();
  };

  computedFeatureValue = (featureName: FeatureFlag) => {
    if (isTestWidgetEnabled()) {
      const testWidgetOverrideFeature = testWidgetManager.settings.featureFlagOverrides[featureName];
      if (testWidgetOverrideFeature !== undefined) {
        return testWidgetOverrideFeature;
      }
    }
    const organizationOverrideFeature = this._organizationOverrides[featureName];
    if (organizationOverrideFeature !== undefined) {
      return organizationOverrideFeature;
    }
    const baseFlagsFeature = this._staticFlags[featureName];
    return Boolean(baseFlagsFeature);
  };

  get allFlags() {
    return Object.freeze(
      Object.keys(this._staticFlags)
        .map(key => ({
          name: key as keyof FeatureFlags,
          value: this.computedFeatureValue(key as keyof FeatureFlags),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }
}

export const featureFlagManager = new FeatureFlagManager();
