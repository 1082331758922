import { Button } from '@pushpay/button';
import { StackItem } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { Group, SettingSwitch } from '../components';
import { useTestWidgetSettings } from '../useTestWidgetSettings';

const useStyles = createUseStyles({
  root: {},
});

const groupId = 'application';

export const ApplicationGroup = ({ classes: classesProp, ...rest }: ComponentProps<unknown, typeof useStyles>) => {
  const [{ pendoWhitelistEnabled, simulateCacheBusting }, dispatch] = useTestWidgetSettings('application');

  return (
    <Group groupName="Application" {...rest}>
      <StackItem alignH="right">
        <Button
          displaySize="small"
          displayStyle="text"
          type="button"
          onClick={() => dispatch({ type: 'applicationReset' })}
        >
          Reset
        </Button>
      </StackItem>
      <SettingSwitch
        checked={pendoWhitelistEnabled}
        id={`${groupId}-pendo-whitelist-enabled`}
        onChange={enabled => dispatch({ type: 'application', pendoWhitelistEnabled: enabled })}
      >
        Pendo whitelist enabled
      </SettingSwitch>
      <SettingSwitch
        checked={simulateCacheBusting}
        id={`${groupId}-simulate-cache-busting`}
        onChange={enabled => dispatch({ type: 'application', simulateCacheBusting: enabled })}
      >
        Simulate cache busting
      </SettingSwitch>
    </Group>
  );
};
