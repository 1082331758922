import { Permissions } from '@pushpay/identity.permissions';

import { useMyIdentity } from './myIdentity';

export function useMyOrganizations() {
  const { allPermissions } = useMyIdentity();

  const organizations =
    allPermissions?.organizationPermissions?.filter(op =>
      op?.permissions.some(p => p?.name === Permissions.engagementAnalyticsAccess.name)
    ) ?? [];

  return organizations;
}
