import * as React from 'react';

import { Columns, Column } from '@pushpay/layout';
import { ComponentProps } from '@pushpay/types';

import { useStyles } from './headerStyles';

export interface HeaderProps {
  leftContent?: React.ReactNode | string;
  rightContent?: React.ReactNode | string;
}

type HeaderComponentProps = ComponentProps<HeaderProps, typeof useStyles>;

export const Header = ({ leftContent = null, rightContent = null, classes: classesProps }: HeaderComponentProps) => {
  const classes = useStyles(classesProps);
  const wrapper = React.useRef(null);

  return (
    <div className={classes.header} ref={wrapper}>
      <Columns className={classes.columns} stackWhen="NEVER">
        <Column classes={{ root: classes.column, inner: classes.inner }}>{leftContent}</Column>
        <Column classes={{ root: classes.column, inner: classes.innerRight }}>{rightContent}</Column>
      </Columns>
    </div>
  );
};
