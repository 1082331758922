import initI18n, { InitOptions } from '@pushpay/i18n';

import { getAppSettings } from './utils';

export default function loadI18n(options: InitOptions<{ common: any }> = {}) {
  const { version } = getAppSettings();

  return initI18n({
    options: {
      appendNamespaceToMissingKey: true,
      backend: { queryStringParams: version },
      ns: ['common'],
      defaultNS: 'common',
      fallbackLng: 'en',
      interpolation: {
        skipOnVariables: false,
      },
      ...options,
    },
  });
}
