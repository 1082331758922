import { Text } from '@pushpay/layout';
import { ComponentProps } from '@pushpay/types';

import PageContent from '@src/components/PageContent';
import useTranslation from '@src/translations';

import { useStyles } from './notFoundStyles';

type NotFoundComponentProps = ComponentProps<object, typeof useStyles>;

export const NotFound = ({ classes: classesProps }: NotFoundComponentProps) => {
  const classes = useStyles(classesProps);
  const { translate } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <PageContent pageName={translate('errors.notFound')} returnTo="home" title={translate('errors.notFound')}>
        <Text type="text-4">{translate('errors.notFound')}</Text>
      </PageContent>
    </div>
  );
};
