import { AppSettings } from '../../settings';

let appSettings: AppSettings | null = null;

export function getAppSettings() {
  if (appSettings === null) {
    appSettings = Object.freeze(JSON.parse(window.pushpay.__SETTINGS__));
  }

  return appSettings as AppSettings;
}
