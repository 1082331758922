import { DateComparisonType } from '@churchcommunitybuilder/js-utils';

export type DateRangeSelectorOptionType = {
  display: string;
  value: DateRangeOptionType;
};

export enum DateRangeDisplay {
  ThisWeek = 'dateRangeSelector.rangeOptions.thisWeek',
  LastWeek = 'dateRangeSelector.rangeOptions.lastWeek',
  Last12Weeks = 'dateRangeSelector.rangeOptions.last12Weeks',
  ThisMonth = 'dateRangeSelector.rangeOptions.thisMonth',
  LastMonth = 'dateRangeSelector.rangeOptions.lastMonth',
  Last12Months = 'dateRangeSelector.rangeOptions.last12Months',
  Custom = 'dateRangeSelector.rangeOptions.custom',
}

export enum DateRangeOptionType {
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek',
  Last12Weeks = 'lastTwelveWeeks',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  Last12Months = 'lastTwelveMonths',
  Custom = 'custom',
}

export enum DateComparisonDisplay {
  None = 'dateRangeSelector.comparisonOptions.None',
  PreviousPeriod = 'dateRangeSelector.comparisonOptions.PreviousPeriod',
  PreviousYear = 'dateRangeSelector.comparisonOptions.PreviousYear',
}

export type OnSelectDateRange = (dateRange: DateRangeOptionType, startDate: Date, endDate: Date) => void;

export interface DateRangeSelectorConfig {
  comparison: DateComparisonType;
  dateRange: DateRangeOptionType;
  endDate: Date;
  startDate: Date;
}
