import {
  convertToDateLocale,
  DateFormats,
  Frequency,
  getFrequencyFromDateRange,
  getFrequencyFromDurationAndPeriod,
  RangeType,
  tryParseISOString,
} from '@churchcommunitybuilder/js-utils';
import { format } from 'date-fns';

import { DateConfig } from '@src/types/DateConfig';

export const formatDate = (date: string | Date, dateFormat: DateFormats = DateFormats.MM_DD_YYYY): string => {
  const parsedDate = tryParseISOString(date);
  const locale = convertToDateLocale(window.navigator.language);
  return format(parsedDate, dateFormat, { locale });
};

export const getFrequency = (dateConfig: DateConfig): Frequency => {
  if (dateConfig.rangeType === RangeType.Custom) {
    return getFrequencyFromDateRange(dateConfig.startDate, dateConfig.endDate);
  }

  return getFrequencyFromDurationAndPeriod(dateConfig.duration, dateConfig.period);
};
