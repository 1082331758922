import * as React from 'react';

import * as ReactRouterDOM from 'react-router-dom';

import { Permission } from '@pushpay/identity.permissions';

import { useMyIdentity } from './myIdentity';

const hasAPermission = (permission: Permission, perms?: { permissions?: { name: string }[] }) =>
  perms?.permissions?.some(p => p?.name === permission.name) ?? false;

type PermissionParams = 'organizationKey' | 'campusKey';

export const useMyPermissions = () => {
  const { allPermissions } = useMyIdentity();
  const { organizationKey: organizationKeyParam, campusKey: campusKeyParam } =
    ReactRouterDOM.useParams<PermissionParams>();

  const hasOrganizationPermission = React.useCallback(
    (permission: Permission, organizationKeyArg?: string) => {
      const organizationKey = organizationKeyArg ?? organizationKeyParam;
      const currentOrganizationPermissions = allPermissions?.organizationPermissions.find(
        op => op?.organizationKey === organizationKey
      );
      return hasAPermission(permission, currentOrganizationPermissions) ?? false;
    },
    [allPermissions?.organizationPermissions, organizationKeyParam]
  );

  const hasCampusPermission = React.useCallback(
    (permission: Permission, campusKeyArg?: string, organizationKeyArg?: string) => {
      const campusKey = campusKeyArg ?? campusKeyParam;
      const organizationKey = organizationKeyArg ?? organizationKeyParam;
      const currentOrganizationPermissions =
        allPermissions?.organizationPermissions.find(op => op?.organizationKey === organizationKey) ??
        allPermissions?.organizationPermissions.find(op =>
          op?.campusPermissions.some(cp => cp?.campusKey === campusKey)
        );
      const currentCampusPermissions = currentOrganizationPermissions?.campusPermissions.find(
        cp => cp?.campusKey === campusKey
      );
      return (
        hasAPermission(permission, currentOrganizationPermissions) ??
        hasAPermission(permission, currentCampusPermissions) ??
        false
      );
    },
    [allPermissions?.organizationPermissions, organizationKeyParam, campusKeyParam]
  );

  const hasPermission = React.useCallback(
    (permission: Permission, organizationKeyArg?: string, campusKeyArg?: string) => {
      const organizationKey = organizationKeyArg ?? organizationKeyParam;
      return (
        hasOrganizationPermission(permission, organizationKey) ||
        hasCampusPermission(permission, campusKeyArg, organizationKey) ||
        false
      );
    },
    [hasCampusPermission, hasOrganizationPermission, organizationKeyParam]
  );

  return {
    hasCampusPermission,
    hasOrganizationPermission,
    hasPermission,
  };
};
