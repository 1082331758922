import * as React from 'react';

export type FallbackProps = {
  error?: Error;
};

type ErrorBoundaryProps = {
  onError?: (error: Error, customData: React.ErrorInfo) => void;
  fallback?: React.ReactNode;
};

type ErrorBoundaryState = {
  error: Error | null;
};

export class ErrorBoundary extends React.Component<React.PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
  state: Readonly<ErrorBoundaryState> = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Send error to New Relic

    const { onError } = this.props;
    if (onError) {
      onError(error, errorInfo);
    }
  }

  render() {
    const { fallback, children } = this.props;
    const { error } = this.state;
    const fallbackStyle = { marginLeft: '72px', marginTop: '8px' };
    return error ? <div style={fallbackStyle}>{fallback}</div> : children;
  }
}
